/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { useGetDistributionChainInfo } from 'api/distributionChainInfoService';
import {
    getCurrencies,
    getDistributors,
    getFilteredData,
    getKeyedData,
    getManufacturers,
} from 'components/organisms/SupplyChainDetails/helpers';
import type { ISupplyChainFilteringState } from 'components/organisms/SupplyChainFilteringForm';
import type {
    IDistributionChainInfoResponse,
    KeyedDistributionChainInfoType,
} from 'types/DistributionChainInfo';

const emptyResponse: IDistributionChainInfoResponse = { distributionChainInfo: [] };

export const useSupplyChainQuery = (
    manufacturerPartNumber: string,
    enableExactMatchesQuery: boolean,
    filters: ISupplyChainFilteringState,
) => {
    const queryNotExactMatches = useGetDistributionChainInfo({
        manufacturerPartNumber,
        exactMatchesOnly: false,
    });

    const queryExactMatches = useGetDistributionChainInfo(
        {
            manufacturerPartNumber,
            exactMatchesOnly: true,
        },
        enableExactMatchesQuery,
    );

    const isAnyFetching =
        (!filters.exactMatchesOnly && queryNotExactMatches.isFetching) ||
        (filters.exactMatchesOnly && queryExactMatches.isFetching);
    const isCurrentError =
        (!filters.exactMatchesOnly && queryNotExactMatches.isError) ||
        (filters.exactMatchesOnly && queryExactMatches.isError);
    const isNotExactSuccess = queryNotExactMatches.isSuccess || queryExactMatches.isSuccess;

    const dataExactMatches = React.useMemo(
        () => getKeyedData((queryExactMatches.data ?? emptyResponse).distributionChainInfo),
        [queryExactMatches.data],
    );
    const dataNotExactMatches = React.useMemo(
        () => getKeyedData((queryNotExactMatches.data ?? emptyResponse).distributionChainInfo),
        [queryNotExactMatches.data],
    );

    const currentData = filters.exactMatchesOnly ? dataExactMatches : dataNotExactMatches;
    const filteredData = React.useMemo(
        () => getFilteredData(currentData, filters) as Array<KeyedDistributionChainInfoType>,
        [currentData, filters],
    );

    const { partDistributors, currencies, manufacturers } = React.useMemo(
        () => ({
            partDistributors: getDistributors(dataNotExactMatches),
            currencies: getCurrencies(dataNotExactMatches),
            manufacturers: getManufacturers(dataNotExactMatches),
        }),
        [dataNotExactMatches],
    );

    return {
        isAnyFetching,
        isCurrentError,
        isNotExactSuccess,

        filteredData,

        partDistributors,
        currencies,
        manufacturers,
    };
};
