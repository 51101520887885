/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const InformationSuccess24Icon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='24'
        height='24'
        aria-label='Infromation Success'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <title>Infromation Success</title>
        <g clipPath='url(#clip0_6053_12418)'>
            <path
                d='M24 12C24 14.3734 23.2962 16.6935 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0866C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.8071 0.693605 16.6689 0.230582 14.3411C-0.232441 12.0133 0.00519937 9.60051 0.913451 7.4078C1.8217 5.21509 3.35977 3.34094 5.33316 2.02236C7.30655 0.703788 9.62663 0 12 0C15.1826 0 18.2349 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12Z'
                fill='#14915A'
            />
            <path
                d='M12 1.5C14.0767 1.5 16.1068 2.11581 17.8335 3.26957C19.5602 4.42332 20.906 6.0632 21.7007 7.98182C22.4955 9.90045 22.7034 12.0116 22.2983 14.0484C21.8931 16.0852 20.8931 17.9562 19.4246 19.4246C17.9562 20.8931 16.0853 21.8931 14.0485 22.2982C12.0117 22.7034 9.90045 22.4955 7.98183 21.7007C6.06321 20.906 4.42333 19.5602 3.26957 17.8335C2.11582 16.1068 1.50001 14.0767 1.50001 12C1.50318 9.2162 2.61045 6.54733 4.57889 4.57889C6.54734 2.61044 9.21621 1.50318 12 1.5ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519937 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2349 1.26428 15.1826 0 12 0Z'
                fill='#464646'
            />
            <path
                d='M4.92139 12.393C5.82139 11.5185 5.72389 11.5665 6.47389 10.839L10.8539 15.1725L16.6094 5.51245C17.4104 5.97895 17.8094 6.30745 18.5594 6.69445C16.5479 9.99445 11.9114 17.7525 11.3054 18.708C8.33839 15.75 5.14189 12.639 4.92139 12.393Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_6053_12418'>
                <rect width='24' height='24' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
