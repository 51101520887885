/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { SearchContext } from 'SearchContextProvider';
import React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';
import { tailwindCssUtil } from 'utils';

import { useGetSearchQuery, useGetSearchQueryManager } from 'api/searchService';
import { Pagination } from 'components/molecules/Pagination';
import type { IPluginData } from 'types/PluginData';

import { SCREEN_BREAKPOINTS } from '../../../../tailwind.config';
import {
    searchTableDefaultColumns,
    searchTableLGColumns,
    searchTableMDColumns,
    searchTableSMColumns,
} from './searchTableColumns';

const emptyData: IPluginData[] = [];

const tailwindConfig = tailwindCssUtil.getTailwindCssConfig();
const screenSizeModel = tailwindCssUtil.convertToScreenSizeModel(tailwindConfig.theme?.screens);
export const useSearchTable = () => {
    const { debouncedValue } = React.useContext(SearchContext);

    const postData = {
        key: 'bbac7d09-118ldbwpe-458h45j9ac-b4hu84b3-5ghj8trf-64bca7daee1e',
        keywords: debouncedValue,
    };

    const queryResult = useGetSearchQuery(postData);
    const queryManager = useGetSearchQueryManager();

    const data = React.useMemo(() => {
        return queryResult?.data?.pages.map((page) => page.parts).flat() || emptyData;
    }, [queryResult?.data]);

    const [currentScreenBreakpoint, setCurrentScreenBreakpoint] = React.useState(
        tailwindCssUtil.matchScreenBreakpoint(screenSizeModel, window.innerWidth),
    );

    React.useEffect(() => {
        const handleResize = () => {
            const screen = tailwindCssUtil.matchScreenBreakpoint(
                screenSizeModel,
                window.innerWidth,
            );
            setCurrentScreenBreakpoint(screen);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const dynamicColumns = React.useMemo(() => {
        if (!currentScreenBreakpoint) {
            return searchTableDefaultColumns;
        }
        switch (currentScreenBreakpoint.name) {
            case SCREEN_BREAKPOINTS.SM:
                return searchTableSMColumns;
            case SCREEN_BREAKPOINTS.MD:
                return searchTableMDColumns;
            case SCREEN_BREAKPOINTS.LG:
                return searchTableLGColumns;
            default:
                return searchTableDefaultColumns;
        }
    }, [currentScreenBreakpoint]);

    const table = useReactTable({
        data: data,
        columns: dynamicColumns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        pageCount: queryResult?.data?.pages.length || 0,
        autoResetPageIndex: false,
        debugAll: false,
        debugColumns: true,
    });

    const prevPage = () => {
        if (table.getCanPreviousPage()) {
            table.previousPage();
        }
    };

    const nextPage = () => {
        if (table.getCanNextPage()) {
            table.nextPage();
        } else {
            queryResult.fetchNextPage();
        }
    };

    React.useEffect(() => {
        table.setPageSize(25);
        table.setPageIndex(table.getPageCount());
    }, [data]);

    React.useEffect(() => {
        table.setPageIndex(0);

        if (debouncedValue.length >= 3) {
            siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createSearchEvent());
        }
    }, [debouncedValue]);

    const lastErroredKeyword = React.useRef<string>();

    React.useEffect(() => {
        if (queryResult.isError) {
            lastErroredKeyword.current = debouncedValue;
        }
    }, [queryResult.isError]);

    React.useEffect(() => {
        // we need to cleanup previous query if it errored, otherwise user will get confusing error
        // message when they type the same keyword again
        if (
            lastErroredKeyword.current !== undefined &&
            lastErroredKeyword.current !== debouncedValue
        ) {
            queryManager.removeQuery(lastErroredKeyword.current);
            lastErroredKeyword.current = undefined;
        }
    }, [debouncedValue]);

    const totalParts = queryResult?.data?.pages[0].totalParts || 0;

    const paginationView = (
        <Pagination table={table} totalParts={totalParts} nextPage={nextPage} prevPage={prevPage} />
    );

    return { data, table, prevPage, nextPage, queryResult, paginationView };
};
