/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

import { CircularProgress } from 'components/atoms/CircularProgress';
import { Message } from 'components/atoms/Message';

export const InitializationMessage = (
    props: Omit<React.ComponentProps<typeof Message>, 'children'>,
) => (
    <Message {...props}>
        <div>
            <CircularProgress />
            <h4 className='mt-2 text-h4 font-semibold'>PartQuest Portal Plugin</h4>
            <p className='mt-0.5 text-p font-normal'>Initialization in Progress...</p>
        </div>
    </Message>
);
