/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { Tooltip } from 'flowbite-react';
import type React from 'react';

const testIds = {
    tooltip: 'ecad-icons-tooltip',
    icons: 'ecad-icons-icons',
    requestButton: 'ecad-icons-request-button',
} as const;

export { testIds as EcadIconsTestIds };

export interface IEcadIconsProps {
    availabilityData: { footprint: boolean; symbol: boolean; model3d: boolean };
}

const enabledIcon = (
    <svg
        className='enabled'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='8' cy='8' r='7.5' fill='#2A932A' stroke='#464646' />
        <path d='M4 7.665L6.89 11.33L12.67 4' stroke='white' />
    </svg>
);

const disabledIcon = (
    <svg
        className='disabled'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g>
            <path
                d='M16 8C16 9.58225 15.5308 11.129 14.6518 12.4446C13.7727 13.7602 12.5233 14.7855 11.0615 15.391C9.59966 15.9965 7.99113 16.155 6.43928 15.8463C4.88743 15.5376 3.46197 14.7757 2.34315 13.6569C1.22433 12.538 0.462403 11.1126 0.153721 9.56072C-0.15496 8.00887 0.00346625 6.40034 0.608967 4.93853C1.21447 3.47672 2.23985 2.22729 3.55544 1.34824C4.87103 0.469192 6.41775 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8Z'
                fill='#DC0000'
            />
            <path
                d='M8 1C8.92085 0.994291 9.83367 1.17145 10.6855 1.52121C11.5374 1.87097 12.3113 2.38637 12.9625 3.03752C13.6136 3.68867 14.129 4.46262 14.4788 5.31448C14.8286 6.16633 15.0057 7.07915 15 8C15.0057 8.92085 14.8286 9.83367 14.4788 10.6855C14.129 11.5374 13.6136 12.3113 12.9625 12.9625C12.3113 13.6136 11.5374 14.129 10.6855 14.4788C9.83367 14.8285 8.92085 15.0057 8 15C7.07916 15.0057 6.16634 14.8285 5.31448 14.4788C4.46262 14.129 3.68868 13.6136 3.03753 12.9625C2.38638 12.3113 1.87098 11.5374 1.52122 10.6855C1.17146 9.83367 0.994294 8.92085 1 8C0.994294 7.07915 1.17146 6.16633 1.52122 5.31448C1.87098 4.46262 2.38638 3.68867 3.03753 3.03752C3.68868 2.38637 4.46262 1.87097 5.31448 1.52121C6.16634 1.17145 7.07916 0.994291 8 1ZM8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9937 5.88022 15.1488 3.84906 13.6499 2.35014C12.1509 0.851219 10.1198 0.00633091 8 0V0Z'
                fill='#464646'
            />
            <path
                d='M8 2C6.81331 2 5.65328 2.35189 4.66658 3.01118C3.67989 3.67047 2.91085 4.60754 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2ZM8 3C8.98891 3 9.95561 3.29324 10.7779 3.84265C11.6001 4.39206 12.241 5.17295 12.6194 6.08658C12.9978 7.00021 13.0969 8.00555 12.9039 8.97545C12.711 9.94536 12.2348 10.8363 11.5355 11.5355C10.8363 12.2348 9.94536 12.711 8.97545 12.9039C8.00555 13.0969 7.00022 12.9978 6.08659 12.6194C5.17296 12.241 4.39206 11.6001 3.84265 10.7779C3.29325 9.95561 3 8.98891 3 8C3 6.67392 3.52679 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3Z'
                fill='white'
            />
            <path
                d='M3.51953 11.485L11.6535 3.80005L12.3405 4.52605L4.20653 12.216L3.51953 11.485Z'
                fill='white'
            />
        </g>
    </svg>
);

const getIcon = (enabled: boolean) => (enabled ? enabledIcon : disabledIcon);

export const EcadIcons: React.FC<IEcadIconsProps> = (props) => {
    const tooltipContent = (
        <div className='w-18'>
            <p className='flex items-stretch pb-0.5 text-right text-notation'>
                <span className='flex-grow pr-1'>Footprint: </span>
                {getIcon(props.availabilityData.footprint)}
            </p>
            <p className='flex items-stretch pb-0.5 text-notation'>
                <span className='flex-grow pr-1'>Symbol: </span>
                {getIcon(props.availabilityData.symbol)}
            </p>
            <p className='flex items-stretch'>
                <span className='flex-grow pr-1 text-notation'>3D Model: </span>
                {getIcon(props.availabilityData.model3d)}
            </p>
        </div>
    );
    if (
        !props.availabilityData.footprint &&
        !props.availabilityData.symbol &&
        !props.availabilityData.model3d
    ) {
        return <></>;
    }

    return (
        <Tooltip
            data-testid={testIds.tooltip}
            content={tooltipContent}
            className='px-2 shadow-tooltip'
            style='light'
            placement='left'
        >
            <div className='flex min-w-[64px]' data-testid={testIds.icons}>
                <svg
                    /* prettier-ignore */
                    /* eslint-disable prettier/prettier */
                    className={`m-0.5 ${!props.availabilityData.footprint && ' opacity-20'}`}
                    /* eslint-enable prettier/prettier */
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M12.5 4.5L7.53359 4.5L6 3.66992V4.5V5.5V7.5H3.5L2 6L0 8L2 10L3.5 8.5H6V10.5V11.5V12.3302L7.53377 11.5L12.5 11.5L14 13L16 11L14 9L12.5 10.5H9.38129L14 8.00005L9.38111 5.5L12.5 5.5L14 7L16 5L14 3L12.5 4.5ZM7 5.5L7.28031 5.5L11.8992 8.00005L7.2805 10.5H7V5.5ZM14.5858 5L14 5.58579L13.4142 5L14 4.41421L14.5858 5ZM2.58579 8L2 8.58579L1.41421 8L2 7.41421L2.58579 8ZM14 11.5858L14.5858 11L14 10.4142L13.4142 11L14 11.5858Z'
                        fill='#3C464B'
                    />
                </svg>
                <svg
                    /* prettier-ignore */
                    /* eslint-disable prettier/prettier */
                    className={`m-0.5 ${!props.availabilityData.symbol && ' opacity-20'}`}
                    /* eslint-enable prettier/prettier */
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect x='0.5' y='0.5' width='14' height='14' stroke='#3C464B' />
                    <rect x='4.5' y='5.5' width='6' height='4' stroke='#3C464B' />
                    <rect x='2' y='3' width='1' height='9' fill='#3C464B' />
                    <rect x='12' y='3' width='1' height='9' fill='#3C464B' />
                    <rect x='4' y='3' width='1' height='1' fill='#3C464B' />
                    <rect x='4' y='11' width='1' height='1' fill='#3C464B' />
                    <rect x='6' y='3' width='1' height='1' fill='#3C464B' />
                    <rect x='6' y='11' width='1' height='1' fill='#3C464B' />
                    <rect x='8' y='3' width='1' height='1' fill='#3C464B' />
                    <rect x='8' y='11' width='1' height='1' fill='#3C464B' />
                    <rect x='10' y='3' width='1' height='1' fill='#3C464B' />
                    <rect x='10' y='11' width='1' height='1' fill='#3C464B' />
                </svg>
                <svg
                    /* prettier-ignore */
                    /* eslint-disable prettier/prettier */
                    className={`m-0.5 ${!props.availabilityData.model3d && ' opacity-20'}`}
                    /* eslint-enable prettier/prettier */
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M2 4.58244L8.30435 8.36505M2 4.58244L8.30435 0.799805L14.6087 4.58244M2 4.58244L2 11.5172L8.30435 15.2998M8.30435 8.36505L14.6087 4.58244M8.30435 8.36505V15.2998M14.6087 4.58244V11.5172L8.30435 15.2998'
                        stroke='#3C464B'
                    />
                </svg>
            </div>
        </Tooltip>
    );
};
