/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useUserData } from 'context/UserContextProvider';
import React from 'react';
import { usePluginRegion, usePluginSessionId } from 'web-component/hooks';

import { InitializationMessage } from 'components/molecules/InitializationMessage';

export const InitializationRoot = ({ children }: React.PropsWithChildren) => {
    const sessionId = usePluginSessionId();
    const region = usePluginRegion();

    const userData = useUserData();

    if (sessionId === undefined || region === undefined || userData === undefined) {
        return <InitializationMessage className='h-screen' />;
    }

    return <div>{children}</div>;
};
