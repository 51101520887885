/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { getParamByParam } from 'iso-country-currency';

// $45.0000
// USD, PLN, ABC
// ABC45.0000

export const formatCurrencyForSupplyChain = (currency?: string, value?: number) => {
    let symbol = currency ?? '';

    try {
        if (currency !== undefined) {
            symbol = getParamByParam('currency', currency, 'symbol');
        }
    } catch {
        // there might be no symbol for given currency, in this case we reuse currency name as a symbol
    }

    const fixedValue =
        value !== undefined && value !== null ? (Math.floor(value * 10000) / 10000).toFixed(4) : '';

    return `${symbol ?? `${currency} `}${fixedValue}`.trim();
};
