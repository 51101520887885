/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const Info24Icon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <title>Info</title>
        <g clipPath='url(#clip0_6393_12402)'>
            <path
                d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z'
                fill='#55A0B9'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 1.02564C5.93903 1.02564 1.02564 5.93903 1.02564 12C1.02564 18.061 5.93903 22.9744 12 22.9744C18.061 22.9744 22.9744 18.061 22.9744 12C22.9744 5.93903 18.061 1.02564 12 1.02564ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z'
                fill='#464646'
            />
            <path d='M12 18L12 10' stroke='white' strokeWidth='2' strokeMiterlimit='10' />
            <path
                d='M11.9928 6C12.3897 6.00471 12.7688 6.16542 13.0482 6.44741C13.3276 6.7294 13.4848 7.11001 13.4858 7.50697C13.4858 7.90294 13.3285 8.2827 13.0485 8.5627C12.7685 8.8427 12.3888 9 11.9928 9C11.5968 9 11.2171 8.8427 10.9371 8.5627C10.6571 8.2827 10.4998 7.90294 10.4998 7.50697C10.5003 7.10986 10.6573 6.72895 10.9368 6.44685C11.2163 6.16475 11.5957 6.00419 11.9928 6Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_6393_12402'>
                <rect width='24' height='24' fill='white' transform='translate(-0.000244141)' />
            </clipPath>
        </defs>
    </svg>
);
