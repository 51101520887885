/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { ComponentProps } from 'react';

import { PartQuestIcon } from 'assets';
import { Message } from 'components/atoms/Message';

export const StartMessage = (props: Omit<ComponentProps<typeof Message>, 'children'>) => {
    return (
        <Message {...props}>
            <div className='w-52'>
                <PartQuestIcon className='mx-auto mb-1' />
                <h2 className='text-[17px]'>Browse PartQuest Portal</h2>
                <p className='text-[14px] font-normal'>
                    Start your search using Manufacturer Part Numbers only.
                </p>
            </div>
        </Message>
    );
};
