/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
        <g>
            <path d='M9.48808 0C5.82512 0 2.8744 2.91824 2.8744 6.54088C2.8744 7.6478 3.17965 8.65409 3.68839 9.55975L0.534181 12.6792C-0.17806 13.3836 -0.17806 14.6918 0.534181 15.3962C0.839428 15.7987 1.34817 16 1.85692 16C2.36566 16 2.8744 15.7987 3.17965 15.3962L6.33386 12.2767C7.2496 12.7799 8.26709 13.0818 9.38633 13.0818C13.0493 13.0818 16 10.1635 16 6.54088C16 2.91824 13.151 0 9.48808 0ZM2.56916 14.7925C2.26391 15.0943 1.55167 15.0943 1.24642 14.7925C0.839428 14.3899 0.839428 13.8868 1.24642 13.4843L4.29889 10.4654C4.70588 10.9686 5.11288 11.3711 5.62162 11.7736L2.56916 14.7925ZM9.48808 12.0755C6.43561 12.0755 3.89189 9.55975 3.89189 6.54088C3.89189 3.52201 6.43561 1.00629 9.48808 1.00629C12.5405 1.00629 15.0843 3.52201 15.0843 6.54088C15.0843 9.55975 12.5405 12.0755 9.48808 12.0755Z' />
        </g>
    </svg>
);
