export const extended_colors = {
panel:{ background_header_default: 'var(--panel-background_header_default)',
background_header_system_default: 'var(--panel-background_header_system_default)',
background_header_selected: 'var(--panel-background_header_selected)',
border_default: 'var(--panel-border_default)'},
background:{ mid: 'var(--background-mid)',
default: 'var(--background-default)',
inverted: 'var(--background-inverted)'},
icon:{ default: 'var(--icon-default)',
default_inverted: 'var(--icon-default_inverted)',
default_button_base: 'var(--icon-default_button_base)',
default_button_high_contrast: 'var(--icon-default_button_high_contrast)',
disabled: 'var(--icon-disabled)',
selected: 'var(--icon-selected)',
background_hover: 'var(--icon-background_hover)',
default_scroll_bar: 'var(--icon-default_scroll_bar)'},
text:{ default: 'var(--text-default)',
default_button_high_contrast: 'var(--text-default_button_high_contrast)',
disabled: 'var(--text-disabled)',
error: 'var(--text-error)',
default_button_base: 'var(--text-default_button_base)',
link: 'var(--text-link)',
selected: 'var(--text-selected)',
hint_text: 'var(--text-hint_text)',
hint_text_inverted: 'var(--text-hint_text_inverted)',
default_inverted: 'var(--text-default_inverted)',
default_command_bar: 'var(--text-default_command_bar)',
hint_text_empty_screen: 'var(--text-hint_text_empty_screen)',
selected_tab: 'var(--text-selected_tab)'},
table:{ border: 'var(--table-border)',
header: 'var(--table-header)',
cell_background1: 'var(--table-cell_background1)',
cell_background2: 'var(--table-cell_background2)',
header_sort_icon: 'var(--table-header_sort_icon)',
cell_background_selected: 'var(--table-cell_background_selected)',
cell_background_hover: 'var(--table-cell_background_hover)',
column_background_hover: 'var(--table-column_background_hover)'},
button:{ background_high_contrast_default: 'var(--button-background_high_contrast_default)',
border_high_contrast: 'var(--button-border_high_contrast)',
background_base_default: 'var(--button-background_base_default)',
border_base: 'var(--button-border_base)',
background_base_hover: 'var(--button-background_base_hover)',
background_high_contrast_hover: 'var(--button-background_high_contrast_hover)',
background_base_pressed: 'var(--button-background_base_pressed)',
background_high_contrast_pressed: 'var(--button-background_high_contrast_pressed)'},
input:{ border_default_hover: 'var(--input-border_default_hover)',
background: 'var(--input-background)',
background_inverted: 'var(--input-background_inverted)',
border_active: 'var(--input-border_active)',
background_disabled: 'var(--input-background_disabled)',
background_error: 'var(--input-background_error)',
border_error: 'var(--input-border_error)',
background_dirty_text: 'var(--input-background_dirty_text)',
border_disabled: 'var(--input-border_disabled)'},
checkbox:{ background_default_off: 'var(--checkbox-background_default_off)',
border_default_off: 'var(--checkbox-border_default_off)',
background_default_on: 'var(--checkbox-background_default_on)',
check_default: 'var(--checkbox-check_default)',
border_default_on: 'var(--checkbox-border_default_on)',
border_hover: 'var(--checkbox-border_hover)',
background_disabled: 'var(--checkbox-background_disabled)',
border_disabled: 'var(--checkbox-border_disabled)',
check_disabled: 'var(--checkbox-check_disabled)'},
command_bar:{ background_hover: 'var(--command_bar-background_hover)',
background_pressedselected: 'var(--command_bar-background_pressedselected)',
background_topbar_selected: 'var(--command_bar-background_topbar_selected)'},
paginator:{ border: 'var(--paginator-border)',
background_disabled: 'var(--paginator-background_disabled)',
background_default: 'var(--paginator-background_default)'},
scroll_bar:{ background_default: 'var(--scroll_bar-background_default)',
background_inverted: 'var(--scroll_bar-background_inverted)',
bar_default: 'var(--scroll_bar-bar_default)',
bar_hover: 'var(--scroll_bar-bar_hover)',
bar_inverted_default: 'var(--scroll_bar-bar_inverted_default)',
bar_inverted_hover: 'var(--scroll_bar-bar_inverted_hover)'},
loading_indicator:{ circular_background: 'var(--loading_indicator-circular_background)',
circular_background_inverted: 'var(--loading_indicator-circular_background_inverted)',
circular_indicator: 'var(--loading_indicator-circular_indicator)',
circular_indicator_inverted: 'var(--loading_indicator-circular_indicator_inverted)'},
global_frame:{ background_default: 'var(--global_frame-background_default)'},
tabs:{ background_default: 'var(--tabs-background_default)',
background_selected: 'var(--tabs-background_selected)'},
switch:{ background_on: 'var(--switch-background_on)',
control: 'var(--switch-control)',
background_off: 'var(--switch-background_off)'}
}