/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

import PQvault16 from 'assets/PQvault16.png';
import Samacsys16 from 'assets/Samacsys16.png';
import type { PluginSourceType } from 'types/PluginData';

interface ISourceIconProps {
    source: PluginSourceType;
}

export const SourceIcon = ({
    source,
    ...props
}: Omit<React.HTMLAttributes<HTMLImageElement>, 'children' | 'alt' | 'src'> & ISourceIconProps) => {
    switch (source) {
        case 'SamacSys':
            return <img {...props} alt={source} src={Samacsys16} />;
        case 'PQ Vault':
            return <img {...props} alt={source} src={PQvault16} />;
        default:
            return <></>;
    }
};
