/*
 * Unpublished work. Copyright 2023 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const ResetIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='M1.08984 9.29995C1.08984 12.9 3.98984 15.9 7.68984 15.9C11.3898 15.9 14.2898 13 14.2898 9.29995L13.5898 9.29995C13.5898 12.6 10.9898 15.2 7.68984 15.2C4.38984 15.2 1.78984 12.5 1.78984 9.29995C1.78984 6.09995 4.38984 3.39995 7.68984 3.39995L10.4898 3.39995L8.38984 5.49995L8.88984 5.99995L11.7898 3.09995L8.88984 0.199951L8.38984 0.699951L10.4898 2.79995L7.68984 2.79995C3.98984 2.69995 1.08984 5.69995 1.08984 9.29995Z'
            fill='currentColor'
        />
        <path
            d='M7.69023 16C3.99023 16 0.990234 13 0.990234 9.3C0.990234 5.6 3.99023 2.6 7.69023 2.6L10.1902 2.6L8.19023 0.7L8.89023 0L11.9902 3.1L8.89023 6.1L8.19023 5.5L10.0902 3.6L7.69023 3.6C4.49023 3.6 1.89023 6.2 1.89023 9.4C1.89023 12.6 4.49023 15 7.69023 15C10.8902 15 13.4902 12.4 13.4902 9.2V9.1L14.4902 9.1V9.2C14.3902 13 11.3902 16 7.69023 16ZM7.69023 2.9C4.19023 2.9 1.29023 5.8 1.29023 9.3C1.29023 12.8 4.19023 15.7 7.69023 15.7C11.1902 15.7 13.9902 12.9 14.0902 9.4L13.6902 9.4C13.5902 12.7 10.8902 15.3 7.69023 15.3C4.39023 15.3 1.69023 12.6 1.69023 9.2C1.69023 5.8 4.39023 3.2 7.69023 3.2L10.7902 3.2L8.59023 5.5L8.89023 5.8L11.5902 3.2L8.89023 0.4L8.59023 0.7L10.7902 2.9L7.69023 2.9Z'
            fill='currentColor'
        />
    </svg>
);
