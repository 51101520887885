/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { SourceIcon } from 'components/atoms/SourceIcon/SourceIcon';
import { SourceName } from 'components/atoms/SourceName/SourceName';
import type { PluginSourceType } from 'types/PluginData';

interface ISourceProps {
    source: PluginSourceType;
}

const testIds = {
    icon: 'source-element-icon',
    name: 'source-element-name',
};

export { testIds as SourceElementTestIds };

export const SourceElement = ({
    source,
    className,
    ...props
}: ISourceProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) => {
    return (
        <div {...props} className={`${className ?? ''} flex justify-center`.trim()}>
            <SourceIcon data-testid={testIds.icon} source={source} />
            <SourceName data-testid={testIds.name} className='px-1' source={source} />
        </div>
    );
};
