/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import type { IPluginData } from 'types/PluginData';

import { SupplyChainDrawer } from './SupplyChainDrawer';

export interface ISupplyChainContext {
    setCurrentComponent: (componentData: IPluginData) => void;
    clear: () => void;
    currentComponent?: IPluginData;
}

export const SupplyChainDrawerContext = React.createContext<ISupplyChainContext>(
    {} as ISupplyChainContext,
);

export const SupplyChainDrawerContextProvider = ({ children }: React.PropsWithChildren) => {
    const [currentComponent, setCurrentComponent] = React.useState<IPluginData>();

    const clear = () => {
        setCurrentComponent(undefined);
    };

    const contextValue = React.useMemo(() => {
        return {
            setCurrentComponent,
            clear,
            currentComponent,
        };
    }, [currentComponent]);

    return (
        <SupplyChainDrawerContext.Provider value={contextValue}>
            {children}
            <SupplyChainDrawer />
        </SupplyChainDrawerContext.Provider>
    );
};
