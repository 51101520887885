/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { Tooltip } from 'flowbite-react';
import type React from 'react';

import { SettingsIcon } from 'assets';
import { Switch } from 'components/atoms/Switch';

const testIds = {
    tableBandingSwitch: 'supply-chain-table-view-view-settings-tooltip-table-banding-switch',
    rowBorderSwitch: 'supply-chain-table-view-view-settings-tooltip-row-border-switch',
    gridSwitch: 'supply-chain-table-view-view-settings-tooltip-grid-switch',
    extendedRowsSwitch: 'supply-chain-table-view-view-settings-tooltip-extended-rows-switch',
    icon: 'supply-chain-table-view-view-settings-tooltip-icon',
    content: 'supply-chain-table-view-view-settings-tooltip-content',
};

export { testIds as SupplyChainTableViewViewSettingsTooltipTestIds };

export interface IViewSettings {
    tableBanding: boolean;
    rowBorder: boolean;
    grid: boolean;
    extendRows: boolean;
}

interface IViewSettingsTooltipProps {
    viewSettings: IViewSettings;
    setViewSettings: React.Dispatch<React.SetStateAction<IViewSettings>>;
}

export const ViewSettingsTooltip = ({
    viewSettings,
    setViewSettings,
}: IViewSettingsTooltipProps) => {
    return (
        <Tooltip
            className='w-[160px] rounded-4 p-0 shadow-tooltip'
            content={
                <div className='m-1 p-2'>
                    <div className='flex'>
                        <span className='block text-notation text-text-default'>Table Banding</span>
                        <div className='ml-auto'>
                            <Switch
                                data-testid={testIds.tableBandingSwitch}
                                checked={viewSettings.tableBanding}
                                onChange={() =>
                                    setViewSettings({
                                        ...viewSettings,
                                        tableBanding: !viewSettings.tableBanding,
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className='mt-2 flex'>
                        <span className='block text-notation text-text-default'>Row Border</span>
                        <div className='ml-auto'>
                            <Switch
                                data-testid={testIds.rowBorderSwitch}
                                checked={viewSettings.rowBorder}
                                onChange={() =>
                                    setViewSettings({
                                        ...viewSettings,
                                        rowBorder: !viewSettings.rowBorder,
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className='mt-2 flex'>
                        <span className='block text-notation text-text-default'>Grid</span>
                        <div className='ml-auto'>
                            <Switch
                                data-testid={testIds.gridSwitch}
                                checked={viewSettings.grid}
                                onChange={() =>
                                    setViewSettings({
                                        ...viewSettings,
                                        grid: !viewSettings.grid,
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className='mt-2 flex'>
                        <span className='block text-notation text-text-default'>Extend rows</span>
                        <div className='ml-auto'>
                            <Switch
                                data-testid={testIds.extendedRowsSwitch}
                                checked={viewSettings.extendRows}
                                onChange={() =>
                                    setViewSettings({
                                        ...viewSettings,
                                        extendRows: !viewSettings.extendRows,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            }
            style='light'
            placement='bottom-start'
            trigger='click'
        >
            <SettingsIcon data-testid={testIds.icon} className='cursor-pointer' />
        </Tooltip>
    );
};
