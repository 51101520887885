/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { usePluginRegion } from 'web-component/hooks';

import { apiRegionMap } from 'const';

export const useApiRootUrl = () => {
    const region = usePluginRegion();

    return React.useMemo(() => {
        if (region === undefined) {
            return undefined;
        }

        const rootUrl = apiRegionMap[region];

        if (rootUrl === undefined) {
            throw new Error(`Region '${region}' has no mapping for root URL`);
        }

        return rootUrl;
    }, [region]);
};
