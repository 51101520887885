/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { usePluginTheme } from 'web-component/hooks';

import { ThemeNameType } from 'types';

interface IThemeRootProps {
    forceTheme?: ThemeNameType;
}

export const ThemeRoot = ({ forceTheme, children }: React.PropsWithChildren<IThemeRootProps>) => {
    if (forceTheme) {
        return <div data-theme={forceTheme}>{children}</div>;
    }

    const theme = usePluginTheme();

    return <div data-theme={theme}>{children}</div>;
};
