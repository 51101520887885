/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { IUserData } from 'context/UserContextProvider';
import React from 'react';
import {
    type IInitParameters,
    siemensAnalyticsEvents,
    siemensAnalyticsService,
} from 'services/SiemensAnalyticsService';

import type { IAuroraOptOut } from './auroraOptOut';

const ANALYTICS_SESSION_TIMEOUT_MINUTES = 60;
const ANALYTICS_SESSION_TIMEOUT_SECONDS = ANALYTICS_SESSION_TIMEOUT_MINUTES * 60;

export interface IUseSiemensAnalyticsConfig {
    environment: string;
    sanInternalUsage: boolean;
    sanProductKey: string;
    featureFlagSiemensAnalyticsEnabled: boolean;
}

interface IUseSiemensAnalyticsResult {
    isAnalyticsInitialized: boolean;
    returnReason: string;
}

export const useSiemensAnalytics = (
    userContext: IUserData | undefined,
    optOutParams: IAuroraOptOut,
    config: IUseSiemensAnalyticsConfig,
): IUseSiemensAnalyticsResult => {
    const isAnalyticsInitialized = React.useRef(false);
    const reason = React.useRef('');

    if (!config.featureFlagSiemensAnalyticsEnabled) {
        console.info('Siemens Analytics Service : Feature is disabled');
        return {
            isAnalyticsInitialized: isAnalyticsInitialized.current,
            returnReason: 'Feature is disabled',
        };
    }

    React.useEffect(() => {
        if (userContext === undefined) {
            reason.current = 'User context is not initialized';
            return;
        }

        if (isAnalyticsInitialized.current) {
            siemensAnalyticsService.endSession();
            isAnalyticsInitialized.current = false;
        }

        try {
            optOutParams.digitalProductExperienceOptOut &&
                siemensAnalyticsService.optOutDigitalProductExperience();
            optOutParams.productExcellenceProgramOptOut &&
                siemensAnalyticsService.optOutProductExcellenceProgram();

            const initParameters: IInitParameters = {
                customerId: userContext.ecaId,
                userId: userContext.userId,
                userEmail: userContext.email,
                productKey: config.sanProductKey,
                internalUsage: config.sanInternalUsage,
                sessionTimeoutInSec: ANALYTICS_SESSION_TIMEOUT_SECONDS,
                productTier: userContext.productTier,
                gatherPIIData: false,
                environment: config.environment,
            };
            siemensAnalyticsService.init(initParameters);
            siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createInitializationEvent());
        } catch (e) {
        } finally {
            reason.current = '';
            isAnalyticsInitialized.current = true;
        }
    }, [userContext, optOutParams]);

    return {
        isAnalyticsInitialized: isAnalyticsInitialized.current,
        returnReason: reason.current,
    };
};
