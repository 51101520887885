/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { formatCurrencyForSupplyChain } from 'utils/currencyUtil';

import type { IPriceBreakdown } from 'types/DistributionChainInfo';
import type { KeyedType } from 'types/utility/Keyed';

const testIds = {
    priceBreakdowns: 'supply-chain-extended-row-view-part-price-breakdowns',
    item: 'supply-chain-extended-row-view-part-price-breakdowns-item',
    quantity: 'supply-chain-extended-row-view-part-price-breakdowns-quantity',
    value: 'supply-chain-extended-row-view-part-price-breakdowns-value',
    seeMore: 'supply-chain-extended-row-view-part-price-breakdowns-see-more',
};

export { testIds as SupplyChainExtendedRowViewPriceBreakdownsTestIds };

interface IPriceBreakdownsProps {
    data: Array<KeyedType<IPriceBreakdown>>;
}

export const PriceBreakdowns = ({ data }: IPriceBreakdownsProps) => {
    const [seeMore, setSeeMore] = React.useState(false);

    return (
        <div className='divide-y divide-dashed divide-siemens-pl-black-21 [&>div:first-child]:pt-0 [&>div:last-child]:pb-0 [&>div]:py-1'>
            {data.map(
                (pb, i) =>
                    (seeMore || i < 3) && (
                        <div data-testid={testIds.item} key={pb.key} className='flex text-notation'>
                            <span data-testid={testIds.quantity}>{pb.quantity}</span>
                            <span data-testid={testIds.value} className='ml-auto'>
                                {formatCurrencyForSupplyChain(pb.currency, pb.value)}
                            </span>
                        </div>
                    ),
            )}

            {data.length > 3 && !seeMore && (
                <div className='text-right'>
                    <span
                        data-testid={testIds.seeMore}
                        className='cursor-pointer text-notation font-semibold text-siemens-blue-7'
                        onClick={() => setSeeMore(true)}
                    >
                        See More
                    </span>
                </div>
            )}
        </div>
    );
};
