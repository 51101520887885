/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { PluginSourceType } from 'types/PluginData';

interface ISourceNameProps {
    source: PluginSourceType;
}

const sourceTextColor = (source: PluginSourceType) => {
    switch (source) {
        case 'SamacSys':
            return 'text-siemens-green-dark';
        case 'PQ Vault':
            return 'text-siemens-blue-10';
        default:
            return '';
    }
};

export const SourceName = ({
    source,
    ...props
}: Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'> & ISourceNameProps) => {
    const styleClasses =
        `${props.className ?? ''} font-semibold text-notation ${sourceTextColor(source)}`.trim();
    return (
        <span {...props} className={styleClasses}>
            {source}
        </span>
    );
};
