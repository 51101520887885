/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

import { SwitchOffIcon, SwitchOnIcon } from 'assets';

interface ISwitchProps {
    checked?: boolean;
    onChange?: () => void;
    'data-testid'?: string;
}

export const Switch = ({
    'data-testid': propTestId,
    ...props
}: ISwitchProps &
    Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange' | 'children' | 'type'>) => {
    const keydownHandler: React.KeyboardEventHandler<HTMLLabelElement> = (e) =>
        e.key === ' ' && props.onChange && props.onChange();

    return (
        <label
            className='group/label inline-block cursor-pointer outline-0'
            tabIndex={0}
            onKeyDown={keydownHandler}
            data-testid={propTestId}
            role='checkbox'
        >
            <input {...props} type='checkbox' className='hidden' />

            {!props.checked && <SwitchOffIcon aria-hidden={true} />}

            {props.checked && <SwitchOnIcon aria-hidden={true} />}
        </label>
    );
};
