/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

export const CheckboxLabel = ({
    children,
    className,
    ...props
}: React.HTMLAttributes<HTMLLabelElement>) => {
    return (
        <label
            {...props}
            className={`ml-1 text-notation text-text-default ${className ?? ''}`.trim()}
        >
            {children}
        </label>
    );
};
