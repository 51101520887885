/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { Table } from '@tanstack/react-table';

import type { IPluginData } from 'types/PluginData';

const testIds = {
    empty: 'pagination-empty',
    pagination: 'pagination',
    prevPage: 'pagination-prev-page',
    currentPage: 'pagination-current-page',
    nextPage: 'pagination-next-page',
} as const;

export { testIds as PaginationTestIds };

export interface IPaginationProps {
    table: Table<IPluginData>;
    totalParts: number;
    prevPage: () => void;
    nextPage: () => void;
}

export const Pagination = ({ table, totalParts, prevPage, nextPage }: IPaginationProps) => {
    const currentPage = table.getState().pagination.pageIndex + 1;
    const totalPages = Math.ceil((totalParts || 0) / table.getState().pagination.pageSize);
    const currentPageMax = Math.min(25 * currentPage, totalParts);

    if (totalParts === 0) {
        return <div data-testid={testIds.empty}></div>;
    }

    return (
        <div
            data-testid={testIds.pagination}
            className='absolute bottom-0 left-0 flex h-11 w-[calc(100vw-20px)] items-center border-t border-siemens-natural-blue-10 bg-white text-notation font-normal'
        >
            <div className='flex-grow pl-4'>
                {`Showing ${25 * currentPage - 24}-${currentPageMax} MPNs of ${totalParts} parts`}
            </div>
            <div className='flex select-none items-center pr-4'>
                <button
                    data-testid={testIds.prevPage}
                    onClick={prevPage}
                    className='rounded-l-12 border border-r-0 border-siemens-natural-blue-10 py-1 pl-2 pr-3 disabled:bg-neutral-100'
                    disabled={currentPage === 1}
                >
                    <svg
                        width='7'
                        height='12'
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path d='M6.5 11.5L1 6L6.5 0.5' stroke='#464646' />
                    </svg>
                </button>
                <div
                    data-testid={testIds.currentPage}
                    className='border border-siemens-natural-blue-10 px-2 py-0.5'
                >
                    {currentPage} of {totalPages}
                </div>
                <button
                    data-testid={testIds.nextPage}
                    onClick={nextPage}
                    className='rounded-r-12 border border-l-0 border-siemens-natural-blue-10 py-1 pl-3 pr-2 disabled:bg-neutral-100'
                    disabled={currentPage === Math.ceil(totalParts / 25)}
                >
                    <svg
                        width='7'
                        height='12'
                        viewBox='0 0 7 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path d='M1 0.5L6.5 6L1 11.5' stroke='#464646' />
                    </svg>
                </button>
            </div>
        </div>
    );
};
