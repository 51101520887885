/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { AccentedButton } from 'components/atoms/Button';
import { RequestDetailsDrawerContext } from 'components/drawers/RequestDetailsDrawer';
import { DrawerContext, DrawerIds } from 'components/molecules/Drawer';
import { EcadIcons } from 'components/molecules/EcadIcons';
import type { IPluginData } from 'types/PluginData';

const DEBUG = false;

const testIds = {
    icons: 'ecad-icons-icons',
    requestButton: 'ecad-icons-request-button',
} as const;

export { testIds as EcadAvailabilityInfoTestIds };

export const EcadAvailabilityInfo = ({ componentData }: { componentData: IPluginData }) => {
    if (
        componentData.dataAvailability.availability.footprint ||
        componentData.dataAvailability.availability.symbol ||
        componentData.dataAvailability.availability.model3d
    ) {
        return (
            <EcadIcons
                availabilityData={componentData.dataAvailability.availability}
                data-testid={testIds.icons}
            />
        );
    }

    const { openDrawer } = React.useContext(DrawerContext);
    const { setCurrentDataEntry } = React.useContext(RequestDetailsDrawerContext);

    const drawerId = DrawerIds.RequestDetails;

    const requestClickHandler = () => {
        DEBUG && console.debug('EcadAvailabilityInfo:requestClickHandler', { componentData });
        setCurrentDataEntry(componentData);
        openDrawer(drawerId);
    };

    return (
        <AccentedButton data-testid={testIds.requestButton} onClick={requestClickHandler}>
            Request
        </AccentedButton>
    );
};
