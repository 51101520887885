/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const SwitchOnIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width='32'
            height='16'
            viewBox='0 0 32 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <title>Switch On Icon</title>
            <rect width='32' height='16' rx='8' fill='var(--switch-background_on)' />
            <circle cx='24' cy='8' r='6' fill='var(--switch-control)' />
        </svg>
    );
};
