/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorMessage } from 'components/molecules/ErrorMessage';

interface AppErrorBoundaryProps extends React.PropsWithChildren {
    showReload?: boolean;
}

export const AppErrorBoundary = ({ children, showReload = false }: AppErrorBoundaryProps) => {
    return (
        <ErrorBoundary fallback={<ErrorMessage className='h-screen' showReload={showReload} />}>
            {children}
        </ErrorBoundary>
    );
};
