/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useMutation } from '@tanstack/react-query';
import { useApiRootUrl, usePluginSessionId } from 'web-component/hooks';

import type { IEcadContentRequest, IEcadContentResponse } from 'types/Ecad';

import apiRoutes from './apiRoutes';
import { api } from './helper';

const DEBUG = false;

export const useEcadRequestMutation = (mfrPartNumber?: string) => {
    const apiRootUrl = useApiRootUrl();
    const sessionId = usePluginSessionId();

    if (apiRootUrl === undefined) {
        throw new Error('apiRootUrl is required for useEcadRequestMutation');
    }

    if (sessionId === undefined) {
        throw new Error('sessionId is required for useEcadRequestMutation');
    }

    return useMutation({
        mutationKey: ['postCreateEcadContent', mfrPartNumber],
        mutationFn: async (postData: IEcadContentRequest) => {
            DEBUG &&
                console.debug('useEcadRequestMutation - mutation', mfrPartNumber, { postData });

            return api.postJson<IEcadContentResponse>(
                sessionId,
                apiRootUrl,
                apiRoutes.postCreateEcadContent,
                postData,
            );
        },
        cacheTime: 0,
    });
};
