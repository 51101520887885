/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const CheckedIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='13'
        height='10'
        viewBox='0 0 13 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <title>Checked Icon</title>
        <path d='M0 5.28978L4.45177 9.75005L13 1.21027L11.7728 0L4.45177 7.31249L1.21024 4.07099L0 5.28978Z' />
    </svg>
);
