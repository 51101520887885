/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { siemensAnalyticsService } from 'services/SiemensAnalyticsService';

import { DragIcon24 } from 'assets';
import type { IPluginData } from 'types/PluginData';

import { useDragButton } from './useDragButton';

export interface IDragButtonProps {
    data: IPluginData;
}

export const DragButton = ({
    data,
    ...props
}: IDragButtonProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) => {
    const { queryObj, setEnabled, getDragData, analyticsEvent } = useDragButton(data);

    return (
        <div
            {...props}
            onMouseDown={() => setEnabled(true)}
            onDragStart={(event) => {
                event.dataTransfer.setData('text', getDragData(queryObj));
                siemensAnalyticsService.logEvent(analyticsEvent);
            }}
            draggable={true}
        >
            <DragIcon24 className='cursor-pointer fill-siemens-stone-dark' />
        </div>
    );
};
