/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const Search24Icon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <g>
            <path
                d='M14.7321 0C9.23768 0 4.81161 4.37736 4.81161 9.81132C4.81161 11.4717 5.26948 12.9811 6.03259 14.3396L1.30127 19.0189C0.232909 20.0755 0.232909 22.0377 1.30127 23.0943C1.75914 23.6981 2.52226 24 3.28537 24C4.04849 24 4.81161 23.6981 5.26948 23.0943L10.0008 18.4151C11.3744 19.1698 12.9006 19.6226 14.5795 19.6226C20.0739 19.6226 24.5 15.2453 24.5 9.81132C24.5 4.37736 20.2265 0 14.7321 0ZM4.35374 22.1887C3.89587 22.6415 2.8275 22.6415 2.36963 22.1887C1.75914 21.5849 1.75914 20.8302 2.36963 20.2264L6.94833 15.6981C7.55882 16.4528 8.16932 17.0566 8.93243 17.6604L4.35374 22.1887ZM14.7321 18.1132C10.1534 18.1132 6.33784 14.3396 6.33784 9.81132C6.33784 5.28302 10.1534 1.50943 14.7321 1.50943C19.3108 1.50943 23.1264 5.28302 23.1264 9.81132C23.1264 14.3396 19.3108 18.1132 14.7321 18.1132Z'
                fill='#879BAA'
            />
        </g>
    </svg>
);
