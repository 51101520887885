/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <g>
            <path
                d='M26.0004 30H4.01041C3.33245 29.9963 2.66694 29.8176 2.0783 29.4812C1.48965 29.1448 0.997821 28.6622 0.65041 28.08C0.257305 27.4465 0.0345391 26.7221 0.00370347 25.9771C-0.0271322 25.2322 0.135009 24.4919 0.47441 23.828L0.48841 23.8L11.1164 4.32601C11.4911 3.62053 12.0507 3.03035 12.7353 2.61878C13.4199 2.2072 14.2036 1.98975 15.0024 1.98975C15.8012 1.98975 16.5849 2.2072 17.2695 2.61878C17.9541 3.03035 18.5138 3.62053 18.8884 4.32601L29.5144 23.8C29.8596 24.4665 30.0263 25.211 29.9983 25.9611C29.9702 26.7111 29.7484 27.4412 29.3544 28.08C29.0071 28.6609 28.5161 29.1427 27.9287 29.479C27.3413 29.8152 26.6772 29.9947 26.0004 30Z'
                fill='#464646'
            />
            <path
                d='M2.25206 24.744C2.07202 25.1022 1.98669 25.5005 2.00419 25.901C2.0217 26.3015 2.14146 26.6909 2.35206 27.032C2.51993 27.324 2.76129 27.567 3.05216 27.7368C3.34302 27.9066 3.67326 27.9974 4.01006 28H26.0001C26.3369 27.9976 26.6672 27.9069 26.9581 27.7371C27.249 27.5672 27.4903 27.3241 27.6581 27.032C27.8706 26.6889 27.9909 26.2967 28.0074 25.8934C28.0239 25.4901 27.9359 25.0893 27.7521 24.73L17.1321 5.28601C16.9115 4.91774 16.5992 4.61291 16.2258 4.40127C15.8523 4.18962 15.4303 4.07837 15.0011 4.07837C14.5718 4.07837 14.1498 4.18962 13.7764 4.40127C13.4029 4.61291 13.0906 4.91774 12.8701 5.28601L2.25206 24.744Z'
                fill='#FA142D'
            />
            <path
                d='M16.4133 19L19.7073 15.708C19.8003 15.6151 19.874 15.5047 19.9244 15.3832C19.9747 15.2617 20.0006 15.1315 20.0006 15C20.0006 14.8686 19.9747 14.7384 19.9244 14.6169C19.874 14.4954 19.8003 14.385 19.7073 14.292C19.6143 14.1991 19.504 14.1253 19.3825 14.075C19.261 14.0247 19.1308 13.9988 18.9993 13.9988C18.8678 13.9988 18.7376 14.0247 18.6161 14.075C18.4947 14.1253 18.3843 14.1991 18.2913 14.292L14.9993 17.586L11.7073 14.292C11.5195 14.1043 11.2649 13.9988 10.9993 13.9988C10.7338 13.9988 10.4791 14.1043 10.2913 14.292C10.1035 14.4798 9.99805 14.7345 9.99805 15C9.99805 15.2656 10.1035 15.5203 10.2913 15.708L13.5853 19L10.2913 22.292C10.1983 22.385 10.1246 22.4954 10.0743 22.6169C10.0239 22.7384 9.99805 22.8686 9.99805 23C9.99805 23.1315 10.0239 23.2617 10.0743 23.3832C10.1246 23.5047 10.1983 23.6151 10.2913 23.708C10.4791 23.8958 10.7338 24.0013 10.9993 24.0013C11.1308 24.0013 11.261 23.9754 11.3825 23.9251C11.504 23.8748 11.6143 23.801 11.7073 23.708L14.9993 20.414L18.2913 23.708C18.4791 23.8958 18.7338 24.0013 18.9993 24.0013C19.2649 24.0013 19.5195 23.8958 19.7073 23.708C19.8951 23.5203 20.0006 23.2656 20.0006 23C20.0006 22.7345 19.8951 22.4798 19.7073 22.292L16.4133 19Z'
                fill='white'
            />
        </g>
    </svg>
);
