/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

export const Message = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            {...props}
            className={`flex w-full select-none items-center justify-center text-center text-text-hint_text_empty_screen ${className ?? ''}`.trim()}
        >
            {props.children}
        </div>
    );
};
