/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const AsteriskIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='11'
        height='10'
        viewBox='0 0 11 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <title>Asterisk Icon</title>
        <path d='M10.125 4.66016L6.91406 5.09375L9.21094 7.56641L7.07812 9.03125L5.47266 6.05469L3.86719 9.03125L1.72266 7.56641L4.03125 5.09375L0.820312 4.66016L1.65234 2.29297L4.60547 3.55859L4.10156 0.195313L6.84375 0.195313L6.33984 3.55859L9.26953 2.29297L10.125 4.66016Z' />
    </svg>
);
