/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <title>Close Icon</title>
        <path
            d='M21.3542 3.354L20.6462 2.646L12.0002 11.293L3.35424 2.646L2.64624 3.354L11.2932 12L2.64624 20.646L3.35424 21.354L12.0002 12.707L20.6462 21.354L21.3542 20.646L12.7072 12L21.3542 3.354Z'
            fill='var(--icon-default)'
        />
    </svg>
);
