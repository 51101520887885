/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { useGetAnalyticsInfoQuery } from 'api/analyticsService';

export interface IUserData {
    userId: string;
    ecaId: string;
    email: string;
    productTier: string;
}

export const UserContext = React.createContext<IUserData | undefined>(undefined);

export const UserContextProvider = ({ children }: React.PropsWithChildren) => {
    const query = useGetAnalyticsInfoQuery();

    const userData: IUserData | undefined = query.isSuccess
        ? {
              userId: query.data.userID,
              ecaId: query.data.ecaID,
              email: query.data.email,
              productTier: query.data.productTier,
          }
        : undefined;

    return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};

export const useUserData = () => {
    return React.useContext(UserContext);
};
