/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

import { AsteriskIcon } from 'assets';

const testIds = {
    coreElement: 'label-core-element',
    mandatoryIcon: 'label-mandatory-icon',
} as const;

export { testIds as LabelTestIds };

export interface ILabelProps extends React.HTMLAttributes<HTMLSpanElement> {
    isMandatory?: boolean;
}

export const Label: React.FC<ILabelProps> = (props) => {
    const { isMandatory, ...htmlProps } = props;
    return (
        <label
            data-testid={testIds.coreElement}
            {...htmlProps}
            className={`flex items-center justify-start justify-items-center pb-1 text-notation font-semibold text-text-default outline-none ${props.className ?? ''}`.trim()}
        >
            {isMandatory && (
                <AsteriskIcon data-testid={testIds.mandatoryIcon} className='fill-text-link pr-1' />
            )}
            {props.children}
        </label>
    );
};
