/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useUserData } from 'context/UserContextProvider';
import React from 'react';
import { IAuroraOptOut } from 'services/SiemensAnalyticsService/auroraOptOut';
import {
    IUseSiemensAnalyticsConfig,
    useSiemensAnalytics,
} from 'services/SiemensAnalyticsService/useSiemensAnalytics';

import {
    environment,
    featureFlagSiemensAnalyticsEnabled,
    sanInternalUsage,
    sanProductKey,
} from 'const';

export const AnalyticsRoot = ({ children }: React.PropsWithChildren) => {
    const userData = useUserData();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [optOut, setOptOut] = React.useState<IAuroraOptOut>({
        productExcellenceProgramOptOut: false,
        digitalProductExperienceOptOut: false,
    });

    const config: IUseSiemensAnalyticsConfig = {
        environment: environment,
        sanInternalUsage: sanInternalUsage,
        sanProductKey: sanProductKey,
        featureFlagSiemensAnalyticsEnabled: featureFlagSiemensAnalyticsEnabled,
    };

    useSiemensAnalytics(userData, optOut, config);

    return <>{children}</>;
};
