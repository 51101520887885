/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export interface IInitializationEvent {
    eventName: 'Initialization';
    eventData: Record<string, never>;
}

export const createInitializationEvent = (): IInitializationEvent => ({
    eventName: 'Initialization',
    eventData: {},
});

export interface ISearchEvent {
    eventName: 'Search';
    eventData: {
        'Event Source': 'SearchView';
        'Event Trigger': 'Unknown';
        'Event Category': 'Search';
        Mode: 'Part Number';
        Type: 'Initial';
        'Part Category': 'Unknown';
    };
}

export const createSearchEvent = (): ISearchEvent => ({
    eventName: 'Search',
    eventData: {
        'Event Source': 'SearchView',
        'Event Trigger': 'Unknown',
        'Event Category': 'Search',
        Mode: 'Part Number',
        Type: 'Initial',
        'Part Category': 'Unknown',
    },
});

export interface IDownloadEvent {
    eventName: 'Download';
    eventData: {
        'Event Source': 'SearchView';
        'Event Trigger': 'Drag&Drop';
        'Event Category': 'Download';
        'CAD Source': 'PQ Vault' | 'SamacSys' | 'Mixed' | 'None';
        'Part Category': string;
        Supplier: string;
        'Supplier Part Number': string;
        'Download Type': 'Part';
    };
}

export const createDownloadEvent = (
    data: Pick<
        IDownloadEvent['eventData'],
        'CAD Source' | 'Part Category' | 'Supplier' | 'Supplier Part Number'
    >,
): IDownloadEvent => ({
    eventName: 'Download',
    eventData: {
        'Event Source': 'SearchView',
        'Event Trigger': 'Drag&Drop',
        'Event Category': 'Download',
        ...data,
        'Download Type': 'Part',
    },
});

export interface IRedirectEvent {
    eventName: 'Redirect';
    eventData: {
        'External Tool': 'PQ Portal';
    };
}

export const createRedirectEvent = (): IRedirectEvent => ({
    eventName: 'Redirect',
    eventData: {
        'External Tool': 'PQ Portal',
    },
});

export interface IFindChipsEvent {
    eventName: 'FindChips';
    eventData: {
        Type: 'Initial View' | 'External Site';
    };
}

export const createFindChipsEvent = (
    type: IFindChipsEvent['eventData']['Type'],
): IFindChipsEvent => ({
    eventName: 'FindChips',
    eventData: {
        Type: type,
    },
});

export interface IFindChipsBuyNowEvent {
    eventName: 'FindChips - Buy now';
    eventData: Record<string, never>;
}

export const createFindChipsBuyNowEvent = (): IFindChipsBuyNowEvent => ({
    eventName: 'FindChips - Buy now',
    eventData: {},
});

export interface IPDFDatasheetOpenEvent {
    eventName: 'PDF Datasheet open';
    eventData: Record<string, never>;
}

export const createPDFDatasheetOpenEvent = (): IPDFDatasheetOpenEvent => ({
    eventName: 'PDF Datasheet open',
    eventData: {},
});

export type AnalyticsEventType =
    | IInitializationEvent
    | ISearchEvent
    | IDownloadEvent
    | IRedirectEvent
    | IFindChipsEvent
    | IFindChipsBuyNowEvent
    | IPDFDatasheetOpenEvent;
