/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { v6 as uuidv6 } from 'uuid';

import type { ISupplyChainFilteringState } from 'components/organisms/SupplyChainFilteringForm';
import type {
    IDistributionChainInfo,
    KeyedDistributionChainInfoType,
} from 'types/DistributionChainInfo';

export const getDistributors = (infos: Array<IDistributionChainInfo>) =>
    infos
        .map((dci) => dci.distributor)
        .filter((d) => d.id !== undefined)
        .map((d) => ({ id: d.id as number, name: d.name }))
        .filter((d, i, array) => array.findIndex((d2) => d2.id === d.id) === i)
        .sort((a, b) => (a.name ?? '-1').localeCompare(b.name ?? '-1'));

export const getCurrencies = (infos: Array<IDistributionChainInfo>) => {
    const allCurrencies = infos
        .flatMap((dci) => dci.parts)
        .flatMap((p) => p.priceBreakdown)
        .filter((pb) => pb.currency !== undefined)
        .map((pb) => pb.currency as string)
        .sort((a, b) => a.localeCompare(b));

    return [...new Set(allCurrencies)];
};

export const getManufacturers = (infos: Array<IDistributionChainInfo>) => {
    const allManufacturers = infos
        .flatMap((dci) => dci.parts)
        .filter((dci) => dci.manufacturerName !== undefined)
        .map((m) => m.manufacturerName as string)
        .sort((a, b) => a.localeCompare(b));

    return [...new Set(allManufacturers)];
};

export const getFilteredData = (
    infos: Array<IDistributionChainInfo>,
    filters: ISupplyChainFilteringState,
) => {
    let filtered = JSON.parse(JSON.stringify(infos)) as Array<IDistributionChainInfo>;

    if (filters.partDistributors.length) {
        filtered = filtered.filter(
            (dci) => dci.distributor.id && filters.partDistributors.includes(dci.distributor.id),
        );
    }

    if (filters.desiredStock) {
        for (const dci of filtered) {
            // @ts-expect-error false complaint about 'filters.desiredStock' being undefined
            dci.parts = dci.parts.filter((p) => p.stock && p.stock >= filters.desiredStock);
        }
    }

    if (filters.currency) {
        for (const dci of filtered) {
            dci.parts = dci.parts.filter((p) =>
                p.priceBreakdown.some((pb) => pb.currency === filters.currency),
            );

            for (const p of dci.parts) {
                p.priceBreakdown = p.priceBreakdown.filter(
                    (pb) => pb.currency === filters.currency,
                );
            }
        }
    }

    if (filters.manufacturer) {
        for (const dci of filtered) {
            dci.parts = dci.parts.filter((p) => p.manufacturerName === filters.manufacturer);
        }
    }

    if (filters.inStockOnly) {
        for (const dci of filtered) {
            dci.parts = dci.parts.filter((p) => p.stock && p.stock > 0);
        }
    }

    filtered = filtered.filter((dci) => !!dci.parts.length);

    return filtered;
};

export const getKeyedData = (
    infos: Array<IDistributionChainInfo>,
): Array<KeyedDistributionChainInfoType> => {
    return infos.map((dci) => ({
        ...dci,
        distributor: {
            ...dci.distributor,
            key: dci.distributor.id ?? uuidv6(),
        },
        parts: dci.parts.map((p) => ({
            ...p,
            key: uuidv6(),
            priceBreakdown: p.priceBreakdown.map((pb) => ({
                ...pb,
                key: uuidv6(),
            })),
        })),
    }));
};
