/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

import { CheckedIcon } from 'assets';

interface ICheckboxProps {
    checked?: boolean;
    onChange?: () => void;
    'data-testid'?: string;
}

export const Checkbox = ({
    'data-testid': propTestId,
    ...props
}: ICheckboxProps &
    Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange' | 'children' | 'type'>) => {
    const keydownHandler: React.KeyboardEventHandler<HTMLLabelElement> = (e) =>
        e.key === ' ' && props.onChange && props.onChange();
    return (
        <label
            role='checkbox'
            aria-checked={props.checked}
            className='group/label inline-block cursor-pointer p-[3px]'
            tabIndex={0}
            onKeyDown={keydownHandler}
            data-testid={propTestId}
        >
            <input {...props} type='checkbox' className='hidden' />
            {!props.checked && (
                <div
                    aria-hidden={true}
                    data-testid={`${propTestId}-internal`}
                    className='h-[18px] w-[18px] rounded-2 border-checkbox-border_default_off bg-checkbox-background_default_off text-checkbox-border_default_off outline outline-1 -outline-offset-1 group-hover/label:text-checkbox-border_default_on group-hover/label:outline-4 group-hover/label:-outline-offset-4'
                />
            )}

            {props.checked && (
                <div
                    aria-hidden={true}
                    data-testid={`${propTestId}-internal`}
                    className='h-[18px] w-[18px] rounded-2 bg-checkbox-background_default_on pt-[4px]'
                >
                    <CheckedIcon className='mx-auto fill-white' />
                </div>
            )}
        </label>
    );
};
