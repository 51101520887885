/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

export interface IDrawerProps {
    isOpen: boolean;
    onClose: React.MouseEventHandler<HTMLDivElement>;
    showBackdrop?: boolean;
    widthStyle?: string;
    customStyle?: string;
}
export const Drawer = ({
    isOpen,
    onClose,
    children,
    widthStyle,
    customStyle,
    showBackdrop,
    ...props
}: IDrawerProps & React.HTMLAttributes<HTMLDivElement>) => {
    const postion = 'fixed right-0 top-0';
    const zIndex = 'z-50';
    const width = widthStyle ?? 'w-1/2';
    const animationStyle = 'ease-in-out';
    const animationDuration = 'duration-500';
    const style = customStyle ?? 'bg-white';
    return (
        <>
            {isOpen && showBackdrop && (
                <div
                    {...props}
                    onClick={onClose}
                    className='fixed inset-0 z-20 h-screen w-screen bg-black opacity-20 duration-300'
                ></div>
            )}

            <div
                {...props}
                role='main'
                aria-roledescription='drawer'
                className={`${postion} ${zIndex} ${width} h-screen shadow-drawer ${style} ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                } ${animationStyle} ${animationDuration}`}
            >
                {isOpen && children}
            </div>
        </>
    );
};
