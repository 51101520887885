/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const DragIcon24 = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        role='img'
        aria-label='Drag Icon'
        {...props}
    >
        <g clipPath='url(#clip0_4086_38931)'>
            <path d='M7 21L8 21L8 23L10 23L10 24L7 24L7 21Z' fill='#464646' />
            <path d='M16 24L20 24L20 23L16 23L16 24Z' fill='#464646' />
            <path d='M11 24L15 24L15 23L11 23L11 24Z' fill='#464646' />
            <path d='M23 21L24 21L24 24L21 24L21 23L23 23L23 21Z' fill='#464646' />
            <path d='M23 20L24 20L24 16L23 16L23 20Z' fill='#464646' />
            <path d='M23 15L24 15L24 11L23 11L23 15Z' fill='#464646' />
            <path d='M21 7L24 7L24 10L23 10L23 8L21 8L21 7Z' fill='#464646' />
            <path
                d='M10.1 20L10.1 21L21 21L21 10.1L20 10.1L20 19.3L8.3 7.7L7.5 8.5L19.3 20L10.1 20Z'
                fill='#464646'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17 1.90735e-06L1.90735e-06 0L0 17L12 17L12 16L1 16L1 1L16 1L16 12L17 12L17 1.90735e-06Z'
                fill='#464646'
            />
        </g>
        <defs>
            <clipPath id='clip0_4086_38931'>
                <rect width='24' height='24' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
