/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type {
    IDistributionChainInfo,
    IDistributionChainInfoResponse,
    IPart,
    IPriceBreakdown,
} from 'types/DistributionChainInfo';

const createLogo = (distributor: string) => {
    const logo = `<svg xmlns="http://www.w3.org/2000/svg" width="139" height="50">
        <rect width="139" height="50" fill="#D7D7CD" stroke="#00646E" stroke-width="4" />
        <text fill="#3C464B" x="10" y="37" font-size="35" font-family="sans-serif">${distributor}</text>
      </svg>`;

    return `data:image/svg+xml;base64,${btoa(logo.replace('(\r\n)', ''))}`;
};

const standardPriceBreakdown: IPriceBreakdown = {
    currency: 'FILL IT',
    quantity: 100,
    value: 500,
};

const standardPart: IPart = {
    manufacturerPartNumber: 'FILL IT',
    manufacturerName: 'Standard manufacturer',
    distributorPartNumber: 'FILL IT',
    stock: 250,
    minimumOrderQuantity: 12,
    buyNowUrl: 'https://siemens.com',
    leadTime: '456',
    containerType: 'Container',
    description: 'Description statement about this component',
    priceBreakdown: [
        { ...standardPriceBreakdown, currency: 'USD' },
        { ...standardPriceBreakdown, currency: 'EUR' },
        { ...standardPriceBreakdown, currency: 'CHF' },
        { ...standardPriceBreakdown, currency: 'PLN' },
        { ...standardPriceBreakdown, currency: 'GBP' },
    ],
};

const emptyPart: IPart = {
    priceBreakdown: [],
};

const recordEverythingUndefinedDistributor: IDistributionChainInfo = {
    distributor: {},
    parts: [
        { ...standardPart, manufacturerPartNumber: '12345', distributorPartNumber: '654321' },
        {
            ...standardPart,
            manufacturerPartNumber: '12345-2',
            distributorPartNumber: '654321-2',
        },
        {
            ...standardPart,
            manufacturerPartNumber: '12345-3',
            distributorPartNumber: '654321-3',
        },
        emptyPart,
        emptyPart,
    ],
};

const recordDistributorNoId: IDistributionChainInfo = {
    ...recordEverythingUndefinedDistributor,
    distributor: {
        name: 'Distributor no ID',
        logoUrl: createLogo('NO ID'),
        authorizedStatus: true,
    },
};

const recordAllOk1: IDistributionChainInfo = {
    distributor: {
        id: 1,
        name: 'OK Distributor',
        logoUrl: createLogo('OK'),
        authorizedStatus: true,
    },
    parts: [
        {
            ...standardPart,
            manufacturerPartNumber: '12345',
            distributorPartNumber: '654321',
            manufacturerName: 'Manufacturer A',
            stock: undefined,
        },
        {
            ...standardPart,
            manufacturerPartNumber: '12345-2',
            distributorPartNumber: '654321-2',
            manufacturerName: 'Manufacturer B',
            stock: 0,
        },
        {
            ...standardPart,
            manufacturerPartNumber: '12345-3',
            distributorPartNumber: '654321-3',
            manufacturerName: 'Manufacturer C',
            priceBreakdown: [
                { ...standardPriceBreakdown, currency: 'USD' },
                { ...standardPriceBreakdown, currency: 'EUR' },
            ],
        },
    ],
};

const recordAllOk2: IDistributionChainInfo = {
    ...recordAllOk1,
    distributor: {
        ...recordAllOk1.distributor,
        id: 2,
        name: 'Reliable',
        logoUrl: createLogo('RELIABLE'),
    },
};

const recordNotExactMatches: IDistributionChainInfo = {
    ...recordAllOk1,
    distributor: {
        ...recordAllOk1.distributor,
        id: 10,
        name: 'Not Exact',
        logoUrl: createLogo('NOT EXACT'),
    },
};

const recordExactMatches: IDistributionChainInfo = {
    ...recordAllOk1,
    distributor: {
        ...recordAllOk1.distributor,
        id: 20,
        name: 'Exact Match',
        logoUrl: createLogo('EXACT MATCH'),
    },
};

const distributionChainInfoNotExactItems: IDistributionChainInfo[] = [
    recordNotExactMatches,
    recordEverythingUndefinedDistributor,
    recordDistributorNoId,
    recordAllOk1,
    recordAllOk2,
];

export const distributionChainInfoNotExact: IDistributionChainInfoResponse = {
    distributionChainInfo: (() => {
        const result: IDistributionChainInfo[] = [];

        for (let i = 0; i < 20; i++) {
            for (const dci of distributionChainInfoNotExactItems) {
                result.push({
                    ...dci,
                    distributor: {
                        ...dci.distributor,
                        id: dci.distributor.id ? dci.distributor.id + 100 * i : undefined,
                        name: `${dci.distributor.name} ${i}`,
                    },
                });
            }
        }

        return result;
    })(),
};

export const distributionChainInfoExactMatches: IDistributionChainInfoResponse = {
    distributionChainInfo: [
        recordExactMatches,
        recordEverythingUndefinedDistributor,
        recordDistributorNoId,
        recordAllOk1,
        recordAllOk2,
    ],
};
