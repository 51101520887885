/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const PdfIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
        <title>Pdf Icon</title>
        <path
            d='M5.10547 23.4895V0.510742L16.1353 0.510742L22.4672 6.84266V23.4895H5.10547Z'
            fill='url(#paint0_linear_2029_15335)'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.59375 0L16.3384 0L22.9767 6.6383V24H4.59375V0ZM21.9555 7.04682L15.93 1.02129L5.61508 1.02129V22.9787H21.9555V7.04682Z'
            fill='#464646'
        />
        <path
            d='M16.4453 6.58721V1.32764L21.7049 6.58721H16.4453Z'
            fill='url(#paint1_linear_2029_15335)'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.9336 7.0978V0.102051L22.9293 7.0978H15.9336ZM20.4786 6.07651L16.9552 2.5531V6.07651H20.4786Z'
            fill='#464646'
        />
        <rect
            x='0.511719'
            y='9.24268'
            width='21.9064'
            height='11.6426'
            fill='url(#paint2_linear_2029_15335)'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 8.73193H22.9277V21.3958H0L0 8.73193ZM21.9065 20.3745V9.75322H1.02141L1.02141 20.3745H21.9065Z'
            fill='#464646'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.11328 11.2341H5.87073C7.09626 11.2341 7.96435 12.1022 7.96435 13.2767V13.9916C7.96435 15.166 7.0452 16.0852 5.87073 16.0852H4.492V18.8937H3.11328V11.2341ZM5.8707 14.7065C6.33027 14.7065 6.58559 14.4001 6.58559 13.9916V13.3277C6.58559 12.8682 6.27921 12.6128 5.8707 12.6128H4.49197V14.7065H5.8707Z'
            fill='white'
        />
        <path
            d='M19.8122 12.6129V11.2341H15.625V18.8937H17.0548V16.0852H19.1484V14.7065H17.0548V12.6129H19.8122Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.67969 18.8937H11.4882C13.0201 18.8937 14.2456 17.6171 14.2967 16.0852V14.0426C14.2967 12.5107 13.0201 11.2341 11.4882 11.2341H8.67969V18.8937ZM11.4882 17.4639H10.1095V12.6128H11.4882C12.2541 12.6128 12.8669 13.2256 12.8669 13.9916V16.0852C12.8669 16.8511 12.2541 17.4639 11.4882 17.4639Z'
            fill='white'
        />
        <defs>
            <linearGradient
                id='paint0_linear_2029_15335'
                x1='-5.50352'
                y1='16.8477'
                x2='18.3936'
                y2='30.4897'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='white' />
                <stop offset='0.2323' stopColor='#FAFAFA' />
                <stop offset='0.4964' stopColor='#EDEDED' />
                <stop offset='0.775' stopColor='#D6D6D6' />
                <stop offset='1' stopColor='#BEBEBE' />
            </linearGradient>
            <linearGradient
                id='paint1_linear_2029_15335'
                x1='12.5198'
                y1='5.25286'
                x2='17.7685'
                y2='10.5016'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='white' />
                <stop offset='0.2214' stopColor='#F8F8F8' />
                <stop offset='0.5415' stopColor='#E5E5E5' />
                <stop offset='0.92' stopColor='#C6C6C6' />
                <stop offset='1' stopColor='#BEBEBE' />
            </linearGradient>
            <linearGradient
                id='paint2_linear_2029_15335'
                x1='-12.6951'
                y1='11.1102'
                x2='-7.0693'
                y2='31.0273'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#73B4C8' />
                <stop offset='0.1747' stopColor='#6AAEC3' />
                <stop offset='0.4567' stopColor='#529EB5' />
                <stop offset='0.8089' stopColor='#2C839F' />
                <stop offset='1' stopColor='#147391' />
            </linearGradient>
        </defs>
    </svg>
);
