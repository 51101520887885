/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import styles from '/src/index.css';
import { App } from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { v6 as uuidv6 } from 'uuid';
import { PluginContextProvider } from 'web-component/PluginContextProvider';

import { isDevelopment } from 'const';
import type { RegionNameType, ThemeNameType } from 'types';

interface IPluginProps {
    sessionId?: string;
    region?: RegionNameType;
    theme?: ThemeNameType;
}

const Plugin = ({ sessionId, region, theme = 'siemens-horizon-light' }: IPluginProps) => {
    const [key, setKey] = React.useState<string>(uuidv6());

    const [currentSessionId, setCurrentSessionId] = React.useState<string | undefined>(sessionId);
    const [currentRegion, setCurrentRegion] = React.useState<RegionNameType | undefined>(region);

    const restartPlugin = () => {
        setKey(uuidv6()); // changing key will reset state of App component
    };

    // restart plugin when any of auth data changes
    React.useEffect(() => {
        if (sessionId !== currentSessionId) {
            if (isDevelopment) {
                console.log(
                    'Session ID changed - PartQuest Plugin will be restarted',
                    `"${sessionId}"`,
                );
            }

            setCurrentSessionId(sessionId);
            restartPlugin();
        }

        if (region !== currentRegion) {
            if (isDevelopment) {
                console.log('Region changed - PartQuest Plugin will be restarted', `"${region}"`);
            }

            setCurrentRegion(region);
            restartPlugin();
        }
    }, [sessionId, region]);

    return (
        <React.StrictMode>
            {/* TODO analytics PIWIK URL depending on the region */}
            <script type='text/javascript' src='/libs/siemensAnalytics/analytics.js' />
            <style type='text/css'>{styles}</style>

            <PluginContextProvider
                theme={theme}
                sessionId={currentSessionId}
                region={currentRegion}
            >
                <App key={key} />
            </PluginContextProvider>
        </React.StrictMode>
    );
};

class PartQuestPlugin extends HTMLElement {
    static observedAttributes = ['session-id', 'region', 'theme'];

    private readonly renderElement: HTMLDivElement;
    private root?: ReactDOM.Root;

    private getNormalizedAttribute<T>(name: string) {
        const value = this.getAttribute(name);
        return value === null ? undefined : (value as T);
    }

    private render() {
        if (!this.root) {
            return;
        }

        const props: IPluginProps = {
            sessionId: this.getNormalizedAttribute<string>('session-id'),
            region: this.getNormalizedAttribute<RegionNameType>('region'),
            theme: this.getNormalizedAttribute<ThemeNameType>('theme'),
        };

        this.root.render(<Plugin {...props} />);
    }

    constructor() {
        super();

        this.renderElement = document.createElement('div');
        this.attachShadow({ mode: 'open' }).appendChild(this.renderElement);

        /* TODO set content security policy */
    }

    // noinspection JSUnusedGlobalSymbols
    connectedCallback() {
        this.root = ReactDOM.createRoot(this.renderElement);
        this.render();
    }

    // noinspection JSUnusedGlobalSymbols
    disconnectedCallback() {
        this.root?.unmount();
    }

    // noinspection JSUnusedGlobalSymbols
    attributeChangedCallback() {
        this.render();
    }
}

if (!customElements.get('partquest-plugin')) {
    customElements.define('partquest-plugin', PartQuestPlugin);
}
